<template>
  <layout-default>

    <v-row style="height: calc(100vh - 64px)">
      <v-col cols="12" sm="3">
        <v-card height="100%">
          <v-card-text>
            <div class="px-4 subtitle-2">
              <v-row @click="orderUp = !orderUp" align="center" class="fill-height" no-gutters style="cursor: pointer">
                <v-icon small v-html="orderUp ? 'mdi-arrow-up' : 'mdi-arrow-down'"></v-icon><div>All Files</div>
              </v-row>
            </div>
            <v-divider class="my-2"></v-divider>
            <v-progress-linear
              indeterminate
              color="primary"
              v-if="loading"
            ></v-progress-linear>
            <v-treeview
              :active="selectedFolder"
              @update:active="test"
              dense
              :items="items"
              transition
              activatable
              return-object
              item-text="real_name"
            >
              <template v-slot:prepend="{ item, open }">
                <v-icon>
                  {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                </v-icon>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="9">

        <v-card class="mb-4">
          <v-card-title>
            <v-row no-gutters>
              <v-col cols="12" class="pa-0 title">
                <v-row no-gutters align="center">
                  <v-col>
                    <v-btn icon @click="back" :disabled="navigationCurrent === 0">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn icon @click="next" :disabled="navigationCurrent === (navigation.length - 1)">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <template v-for="(breadcrumb, i) in breadcrumbs">
                      <span class="subtitle-1" @click="test([breadcrumb])" style="cursor: pointer">
                        {{breadcrumb.name}}
                      </span>
                      <span class="px-1 subtitle-1" v-if="breadcrumbs.length - 1 > i">/</span>
                    </template>
                    <template v-if="!breadcrumbs.length">
                      Admin Dashboard
                    </template>
                  </v-col>
                  <v-text-field
                    filled
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                    v-model="search"
                    hide-details
                    :loading="loading"
                    clearable
                    @click:clear="desserts = []"
                    placeholder="please type 3 letters minimum"
                    dense
                    style="max-width: 400px">
                  </v-text-field>
                </v-row>
                <v-divider class="pb-2"></v-divider>
              </v-col>
              <v-col cols="12" class="pa-0" v-if="selectedFolder.length">
                <v-btn color="primary" dark small class="mr-2" @click="dialogUpload = true" v-if="user.permissions.includes('upload-file')">Upload File</v-btn>
                <v-btn outlined small class="mr-2" @click="dialog = true" v-if="user.type === 'admin'">New Folder</v-btn>
              </v-col>
              <v-col cols="12" class="pa-0 subtitle-1" v-else>
                Select folder
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <v-progress-linear
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-linear>
        <v-data-table
          dense
          :headers="headers"
          :items="desserts"
          item-key="name"
          class="elevation-1"
        >
          <template v-slot:item.real_name="{ item }">
              <v-icon
                v-if="item.type === 'folder'"
                color="primary"
                @click="test([item])"
                style="padding-bottom: 4px">
                mdi-folder
              </v-icon>
              <v-icon
                v-else
                style="padding-bottom: 4px">
                mdi-file
              </v-icon>
            <span @click="test([item])" class="px-1" style="cursor: pointer">{{item.real_name}}</span>
          </template>
          <template v-slot:item.size="{ item }">
            {{(item.size / 1024 / 1024).toFixed(2)}}MB
          </template>
          <template v-slot:item.created_at="{ item }">
            {{item.created_at | dateTime}}
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip bottom v-if="user.permissions.includes('read-only')">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-if="item.type !== 'folder'"
                  @click="download(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-download
                </v-icon>
              </template>
              <span>Download File</span>
            </v-tooltip>
            <v-tooltip bottom v-if="user.type === 'admin'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-if="item.type === 'folder'"
                  @click="folderName = {...item}; dialog = true"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Rename Folder</span>
            </v-tooltip>

            <v-tooltip bottom v-if="user.permissions.includes('delete-file') && item.type !== 'folder'">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="remove(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Remove</span>
            </v-tooltip>

            <v-tooltip bottom v-if="
                user.permissions.includes('delete-file') &&
                item.type === 'folder' &&
                user.type === 'admin'
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="removeAll(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Remove All</span>
            </v-tooltip>

          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card>
          <v-card-title class="text-h5 mb-4">
            <v-icon x-large class="mr-2" color="primary">mdi-folder</v-icon>
            <span v-if="folderName.id">Rename Folder</span>
            <span v-else>Create Folder</span>
          </v-card-title>

          <v-card-text>
            <v-text-field
              label="Folder Name"
              v-model="folderName.real_name"
              outlined
              dense
              :rules="nameRules"
              required
            >
            </v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              @click="folderNameReset(); dialog = false"
            >
              Cancel
            </v-btn>

            <v-btn
              color="primary"
              @click="newFolder"
              tile
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-model="dialogUpload"
      max-width="600"
    >
        <v-card>
          <v-card-title class="text-h5 mb-4">
            <v-icon x-large class="mr-2" color="primary">mdi-folder</v-icon>
            <span>Upload File</span>
          </v-card-title>

          <v-card-text>
            <div @dragover.prevent @drop.prevent>
              <!-- @drop="select($event, false)" -->
              <div class="drag-area" @drop="selectTest2" @dragover="$event.target.classList.add('active');" @dragleave="$event.target.classList.remove('active')">
                <span class="title">Drag & Drop</span>
                <input type="file" id="upload" hidden multiple @change="select"/>
                <input type="file" id="uploadFolder" hidden multiple @change="select"  webkitdirectory mozdirectory directory/>
                <label for="upload">or <strong class="primary--text button">browse</strong></label>
                <label for="uploadFolder">or upload <strong class="primary--text button">folder</strong></label>
                <!--                <v-row justify="center" class="drag-area-progress full-width">-->
                <template v-for="(file, i) in files" v-if="files.length">
                  <div>{{file.name}}</div>
                  <v-progress-linear :value="progressTotal(file, i)" height="25">
                    <template v-slot:default="{ value }">
                      <strong class="white--text">{{ Math.ceil(value) }}%</strong>
                    </template>
                  </v-progress-linear>
                </template>
                <!--                </v-row>-->
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="dialogUpload = false; files = []"
              text
            >
              Close
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <confirm-box ref="confirmBox"></confirm-box>

    <v-snackbar
      v-model="snackbar"
      top
      color="error"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </layout-default>
</template>

<script>

import layoutDefault from '../layouts/default'
import axios from '../plugins/axios';
import moment from 'moment'
import configFile from '../config'
import confirmBox from '../components/confirm-box'
import debounce from 'lodash.debounce'

export default {
  components: {layoutDefault, confirmBox},
  data() {
    return {
      configFile: configFile,
      folders: [],

      active: [],
      avatar: null,
      open: [],
      users: [],

      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'real_name',
        },
        { text: 'Type', value: 'type' },
        { text: 'Size', value: 'size' },
        { text: 'Created On', value: 'created_at' },
        { text: 'Action', value: 'action', sortable: false }
      ],
      desserts: [],

      folderName: {
        id: null,
        real_name: ''
      },
      dialog: false,
      selectedFolder: [],
      breadcrumbs: [],
      loading: false,

      file: {size: null},
      chunks: [],
      uploaded: 0,
      valid: true,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      dialogUpload: false,
      files: [],
      progress: [],
      navigation: [],
      navigationCurrent: 0,
      lastId: null,
      snackbar: false,
      text: '',
      search: '',
      orderUp: true
    }
  },
  created() {
    this.getFolders()
  },
  methods: {
    selectTest2(event) {
      this.getFilesDataTransferItems(event.dataTransfer.items)
        .then(res => {
          this.select(null, false)
        })
    },

    getFilesDataTransferItems(dataTransferItems) {
      let _this = this
      function traverseFileTreePromise(item) {
        return new Promise(resolve => {
          if (item.isFile) {
            item.file(file => {
              if(item.fullPath.charAt( 0 ) == '/') {
                file.webkitRelativePathCustom = item.fullPath.slice(1); //save full path
              }
              else {
                file.webkitRelativePathCustom = item.fullPath; //save full path
              }
              _this.files.push(file)
              resolve(file);
            });
          } else if (item.isDirectory) {
            let dirReader = item.createReader();
            dirReader.readEntries(entries => {
              let entriesPromises = [];
              for (let entr of entries)
                entriesPromises.push(
                  traverseFileTreePromise(entr)
                );
              resolve(Promise.all(entriesPromises));
            });
          }
        });
      }

      let files = [];
      return new Promise((resolve, reject) => {
        let entriesPromises = [];
        for (let it of dataTransferItems)
          entriesPromises.push(
            traverseFileTreePromise(it.webkitGetAsEntry())
          );
        Promise.all(entriesPromises).then(entries => {
          resolve(files);
        });
      });

    },

    removeAll(item) {
      this.$refs.confirmBox.open('Remove Folder', 'Are you sure?', {color: 'primary'})
        .then(() => {
          axios.delete(`/files/${item.id}/all`).then(res => {
            let index = this.desserts.findIndex(i => i.id === item.id)
            this.desserts.splice(index, 1)

            this.getFolders()
          })
        })
    },

    remove(item) {
      if(item.type === 'folder') {
        this.text = 'Folder is not allow to remove'
        this.snackbar = true
      }

      this.$refs.confirmBox.open('Remove file', 'Are you sure?', {color: 'primary'})
        .then(() => {
          axios.delete(`/files/${item.id}`)
            .then(res => {
              let index = this.desserts.findIndex(i => i.id === item.id)
              this.desserts.splice(index, 1)
            })
            .catch(err => {
              this.text = err.response.data.error
              this.snackbar = true
            })
        })
        .catch(() => {
          console.log('no')
        })
    },
    back() {
      if(this.navigationCurrent === 0) return
      this.navigationCurrent--
      let eventId = this.navigation[this.navigationCurrent]
      if(eventId) this.test([{id: eventId, type: 'folder'}])
    },
    next() {
      if(this.navigationCurrent >= (this.navigation.length - 1)) return
      this.navigationCurrent++
      let eventId = this.navigation[this.navigationCurrent]
      if(eventId) this.test([{id: eventId, type: 'folder'}])
    },
    progressTotal(file, i) {
      return Math.floor((this.progress[i] * 10) / file.size);
    },
    formData(chunk, fileName, isLast, path) {
      let formData = new FormData;

      formData.set('is_last', isLast);
      formData.set('file', chunk, `${fileName}.part`);
      formData.set('parent_id', this.selectedFolder[0].id);
      formData.set('path', path);

      return formData;
    },
    download(file) {
      axios.get(this.configFile.api + '/download/' + file.id, { responseType: 'blob' }).then(res => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.setAttribute('download',file.real_name);
        document.body.appendChild(link);
        link.click();
      })
    },
    async select(event, type = true) {
      if(type) this.files = event.target.files
      for(let i = 0; i < this.files.length; i++) {
        let file = this.files[i]

        let size = 2000000, chunks = Math.ceil(file.size / size);
        this.progress[i] = 0;
        for (let ii = 0; ii < chunks; ii++) {

          let ch = file.slice(
            ii * size, Math.min(ii * size + size, file.size), file.type
          )

          let path = file.webkitRelativePath != '' ? file.webkitRelativePath : file.webkitRelativePathCustom
          console.log('path', path)
          let formData = this.formData(ch, file.name, ii === chunks - 1, path);
          await axios.post(this.configFile.api + '/upload-file', formData, {headers: {
              'Content-Type': 'application/octet-stream'
            }, onUploadProgress: event => {
              let a = this.progress[i] + event.loaded
              this.$set(this.progress, i, a)
            }}).then(response => {
            if(ii === chunks - 1) {
              this.progress[i] = file.size * 10
              this.desserts.push({
                real_name: file.name,
                size: file.size,
                type: file.type,
                id: response.data.data.id
              })
            }
          })
        }
      }
      this.getFolders()
      let eventId = this.selectedFolder[0].id
      this.lastId = eventId - 1
      if(eventId) this.test([{id: eventId, type: 'folder'}])
    },
    upload() {
      axios(this.config).then(response => {
        this.chunks.shift();
        if(this.chunks.length === 0) {
          this.desserts.push({
            real_name: this.file.name,
            size: this.file.size,
            type: this.file.type,
            id: response.data.data.id
          })
        }
      }).catch(error => {});
    },

    test(event) {
      if(event.length == 0 || (event[0].type && event[0].type !== 'folder') || this.lastId === event[0].id) return
      this.loading = true
      this.lastId = event[0].id

      this.selectedFolder = event

      let index = this.navigation.indexOf(event[0].id)
      if(index < 0) {
        this.navigation.splice(this.navigationCurrent + 1, 0, event[0].id);
        let index = this.navigation.indexOf(event[0].id)
        this.navigationCurrent = index
      }
      else {
        this.navigationCurrent = index
      }

      axios.get(this.configFile.api + '/get-files-and-folders/' + event[0].id).then(res => {
        this.desserts = res.data.files.children_files

        this.breadcrumbs = []

        let _this = this
        function recursive(item) {
          _this.breadcrumbs.unshift({name: item.real_name, id: item.id})
          if(item.parent) recursive(item.parent)
        }

        if(res.data.breadcrumbs && res.data.breadcrumbs.parent) {
          this.breadcrumbs.unshift({name: res.data.breadcrumbs.real_name, id: res.data.breadcrumbs.id})
          recursive(res.data.breadcrumbs.parent)
        }

      }).finally(() => {
        this.loading = false
      })
    },
    newFolder() {
      if(this.$refs.form.validate()) {
        this.loading = true
        if (!this.folderName.id) {
          axios.post(this.configFile.api + '/folders', {
            name: this.folderName.real_name,
            parent_id: this.selectedFolder.length ? this.selectedFolder[0].id : 0
          }).then(res => {
            this.desserts.push(res.data)
            this.getFolders()
          }).finally(() => {
            this.folderNameReset()
            this.dialog = false
            this.loading = false
          })
        } else {
          axios.patch(this.configFile.api + '/folders/' + this.folderName.id, {
            params: {
              real_name: this.folderName.real_name,
              parent_id: this.selectedFolder.length ? this.selectedFolder[0].id : 0
            }
          }).then(res => {
            this.getFolders()
            let dessert = this.desserts.find(i => i.id === this.folderName.id)
            dessert.real_name = this.folderName.real_name
          }).finally(() => {
            this.folderNameReset()
            this.dialog = false
            this.loading = false
          })
        }
      }
    },

    folderNameReset() {
      this.folderName = {
        id: null,
        real_name: ''
      }
    },

    async getFolders(path = null) {
      this.loading = true
      let url = this.$store.state.user.company.name
      if(path) url = path.path
      if(this.orderUp) url += '&orderUp'
      return axios.get(this.configFile.api + '/folders2?url=' + url, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('samuel-clients')
        }
      })
        .then(res => {
          if(path) {
            path.children = res.data.length ? res.data : null
          }
          else {
            this.folders = res.data
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    items() {
      return this.folders
    },
    user() {
      return this.$store.state.user
    }
  },
  filters: {
    dateTime(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  watch: {
    search: debounce(function (val) {
      if(val.length < 3) {
        this.desserts = []
        return
      }
      this.loading = true
      axios.get(this.configFile.api + '/folders/search?q=' + val)
        .then(res => {
          this.desserts = res.data
        })
        .finally(() => {
          this.loading = false
        })
    }, 350),
    orderUp() {
      this.getFolders()
    }
  },
}

</script>

<style>

.v-treeview-node__content {
  cursor: pointer!important;
}

.drag-area {
  height: 200px;
  border: 3px dashed #e0eafc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px auto;
  z-index: 999;
}

.drag-area .support {
  font-size: 12px;
  color: gray;
  margin: 10px 0 15px 0;
}

.drag-area.active {
  border: 2px solid #1683ff;
}



</style>
