<template>
  <layout-login>
    <v-col cols="12" class="help-page my-15">
      <h3 class="pb-4 font-weight-medium">Need Help</h3>
      <p class="pb-2">
        If you need assistance logging into the Samuel Client Portal,
        please get in touch with our support team:
      </p>
      <p>
        Canada/US and International
      </p>
      <p>
        +1 (613) 292-3936
      </p>
      <p>
        9:00 a.m. - 5:00 p.m (ET)
      <p>
        support@samuelclient.com
      </p>
      <p>
        Samuel Client Portal support team hours are from Monday to Friday (excluding Canadian statutory holidays)
      </p>
    </v-col>
  </layout-login>
</template>

<script>

import layoutLogin from '../layouts/login'

export default {
  components: {layoutLogin}
}

</script>

<style>
  .help-page {
    text-align: left;
    color: white;
    width: 100%;
  }
</style>
