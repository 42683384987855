<template>
  <v-app id="inspire">
    <v-app-bar app clipped-left style="background-color: #062541">
      <router-link :to="{name: 'slug-home', params: {slug: $store.state.user.company.name}}" class="pt-1">
        <img src="../assets/samuel_clients_horizontal_white.png" height="46px"/>
      </router-link>
      <v-row class="white--text fill-height px-2 title-client" align="end" no-gutters>{{$store.state.user.company.name}}</v-row>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        min-width="200px"
        rounded
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            x-large
            v-on="on"
          >
            <v-avatar
              color="#2fa1e5"
              size="48"
            >
              <span class="white--text text-h5">{{ initials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
                color="#2fa1e5"
              >
                <span class="white--text text-h5">{{ initials }}</span>
              </v-avatar>
              <h3>{{ $store.state.user.name }}</h3>
              <h3 class="py-1">-{{ $store.state.user.type }}-</h3>
              <p class="text-caption mt-1">
                {{ $store.state.user.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
              >
                Edit Account
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn
                depressed
                rounded
                text
                @click="logout"
              >
                Logout
              </v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container>
        <slot></slot>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import analyticsServices from "../services/analytics";

export default {
  data: () => ({
    drawer: null,
    items: [
      { title: 'Documents', icon: 'mdi-text-box-multiple', to: 'documents' },
      { title: 'Events', icon: 'mdi-calendar-text', to: 'events' },
      { title: 'Reports', icon: 'mdi-chart-bar', to: 'reports' },
      { title: 'Notifications', icon: 'mdi-bell', to: 'notifications' }
    ]
  }),
  created() {
    window.addEventListener("beforeunload", this.leaving);
  },
  methods: {
    logout() {
      analyticsServices.store({type: 'logout', params: []}).then(res => {
        localStorage.removeItem('samuel-clients');
      })
      this.$store.dispatch('LOGOUT_USER').then(res => {
        this.$router.replace({name: 'login'})
      })
    },
    leaving() {
      analyticsServices.store({type: 'close', params: []})
    }
  },
  computed: {
    initials() {
      return this.$store.state.user.name
        && this.$store.state.user.name.split(" ").map((n)=>n[0]).join("");
    }
  }
}
</script>
