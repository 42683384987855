import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-default',[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VToolbar,{staticClass:"mb-2",attrs:{"dense":"","elevation":"1"}},[_vm._v("Total Users: "+_vm._s(_vm.item.total))]),_c(VCard,[_c(VCardText,[(_vm.item)?_c('canvas-chart',{attrs:{"data":_vm.item}}):_vm._e()],1)],1)],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VToolbar,{staticClass:"mb-2",attrs:{"dense":"","elevation":"1"}},[_vm._v("Total Users: "+_vm._s(_vm.item2.total))]),_c(VCard,[_c(VCardText,[(_vm.item2)?_c('canvas-chart',{attrs:{"data":_vm.item2}}):_vm._e()],1)],1)],1)],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VCard,[_c(VCardText,[_c(VCardTitle,[_vm._v("Sessions by country")]),_vm._l((_vm.item3),function(el,i){return _c(VListItem,{key:i,attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(el.country))]),_c(VListItemSubtitle,[_vm._v(_vm._s(el.city))]),_c(VListItemSubtitle,[_vm._v("IP: "+_vm._s(el.ip)+" Count: "+_vm._s(el.count))])],1)],1)})],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }