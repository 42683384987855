<template>
  <layout-default>
    <v-row v-if="$store.state.user.company.name">
      <template v-for="item in items">
        <v-col cols="12" sm="12" md="6" lg="4" xl="3" v-if="item.userType.includes(user.type)">
          <v-hover
            v-slot="{ hover }"
            open-delay="10"
          >
          <v-card
            class="mx-auto"
            max-width="400"
            height="160"
            outlined
            :elevation="hover ? 2 : 0"
            :to="{name: item.to, params: {slug: $store.state.user.company.name.replaceAll(' ', '-').toLowerCase()}}"
            :disabled="item.demo"
          >
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="auto" class="px-2">
                      <v-icon>{{item.icon}}</v-icon>
                    </v-col>
                    <v-col>
                      <v-row>
                        <v-col cols="12" class="headline pb-0 black--text">{{item.title}}</v-col>
                        <v-col cols="12">{{item.text}}</v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="900px"
      persistent
    >
      <v-card>
        <v-card-title style="font-size: 16px">
          Terms and Conditions
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 600px;" id="scroll-target">
          <terms-and-conditions v-scroll:#scroll-target="onScroll"></terms-and-conditions>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="font-size: 14px">
          Click here to indicate that you have read and agree to the Terms and Conditions of the Samuel Client
          Portal Agreement and User Policy.
          <v-checkbox v-model="checkbox" :disabled="offsetTop !== 0"></v-checkbox>
          <v-btn color="primary" :disabled="offsetTop !== 0 || !checkbox" @click="next">Next</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </layout-default>
</template>

<script>

import layoutDefault from '../layouts/default'
import termsAndConditions from '../components/terms-and-conditions'
import axios from "../plugins/axios";
import moment from "moment";

export default {
  components: {layoutDefault, termsAndConditions},
  data() {
    return {
      items: [
        {
          title: 'Central File System',
          text: 'Access to all Samuel Client files.',
          icon: 'mdi-chart-bar',
          to: 'slug-file-system',
          userType: ['admin', 'client', 'admin-client']
        },
        {
          title: 'Client Profiles and User Permissions',
          text: 'Adding and editing Samuel Client accounts and individual user permissions.',
          icon: 'mdi-domain',
          to: 'companies',
          demo: false,
          userType: ['admin']
        },
        {
          title: 'Users',
          text: 'List of Samuel Client Portal Users.',
          icon: 'mdi-account',
          to: 'users',
          demo: false,
          userType: ['admin']
        },
        {
          title: 'Analytics',
          text: 'Analytical and statistical information on Samuel Client Portal usage.',
          icon: 'mdi-account',
          to: 'analytics',
          demo: false,
          userType: ['admin']
        }
      ],
      dialog: false,
      offsetTop: -1,
      checkbox: false
    }
  },
  created() {
    if(this.user && !this.user.terms_and_condition) {
      this.dialog = true
    }
  },
  methods: {
    onScroll (e) {
      this.offsetTop = e.target.offsetHeight + e.target.scrollTop - e.target.scrollHeight
    },
    next() {
      axios.post('/users/terms-and-conditions', {
        clientDateTime: moment()
      }).then(res => {
        this.dialog = false
      })
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  }
}

</script>
