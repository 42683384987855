<template>
  <layout-login>
    <v-col cols="12" class="privacy-page my-15">
      <h3 class="pb-4 font-weight-medium">Privacy Statement</h3>
      <h3 class="pb-2 font-weight-light">Effective Date: November 1, 2022</h3>
      <h3 class="font-weight-light">Privacy</h3>
      <p>
        The Samuel Group of Companies endeavours to maintain the highest standards of confidentiality and to respect the
        privacy of our clients and associated persons, our employees, and other individuals.
        Please note that other, more specific policies may be available to you on sites through which you use one of our
        affiliated companies.
      </p>
      <p>
        Please note that this Privacy Statement addresses the data collection and handling practices associated with this
        Site only. Other websites, including those provided by members of Samuel-affiliated companies, are governed by
        their own privacy statements. We encourage you to review these privacy statements.
      </p>
      <h3 class="font-weight-medium">Third-party websites</h3>
      <p>
        This Privacy Statement does not address, and we are not responsible for, the privacy practices of any third parties,
        including those that operate websites to which this Site links. Including a link on this Site does not imply that we or
        our affiliates endorse the practices of the linked website.
      </p>
      <h3 class="font-weight-medium">Data security</h3>
      <p>
        We use reasonable organizational, technical, and administrative measures to protect data under our control.
        Unfortunately, no data transmission over the internet or data storage system can be guaranteed to be 100% secure.
        If you feel that the security of any data we hold about you has been compromised, please immediately notify us of
        the problem by contacting us under the &quot;Need Help&quot; section.
      </p>
      <h3 class="font-weight-medium">Updates to this privacy statement</h3>
      <p>
        We may make changes to this Privacy Statement from time to time. The &quot;Effective Date&quot; at the top of this page
        shows when this Privacy Statement was last revised. Any changes will become effective when we post a revised
        version of this Privacy Statement on this Site. Your use of this Site is subject to the terms in the version of this
        Privacy Statement posted on this Site at the time of your visit. We encourage you to review this Privacy Statement
        periodically to remain informed about how we are protecting your data.
      </p>
    </v-col>
  </layout-login>
</template>

<script>

import layoutLogin from '../layouts/login'

export default {
  components: {layoutLogin}
}

</script>

<style>
.privacy-page {
  text-align: left;
  color: white;
  width: 100%;
}
</style>
