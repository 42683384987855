<template>
  <layout-default class="company">
    <v-data-table
      :headers="headers"
      :items="desserts"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Companies</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                New Company
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                    <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.name"
                        label="Name"
                        :rules="[rules.required, rules.company]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.description"
                        :rules="[rules.required]"
                        label="Description"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.folder_path"
                        label="Company Folder"
                        :rules="[rules.required]"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.state="{ item }">
        <v-select
          :items="state"
          v-model="item.state"
          solo
          hide-details
          dense
          @change="updateState(item)"
        ></v-select>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          outlined
          class="mx-1"
          :to="{name: 'company-id-users', params: {id: item.id}}"
          color="primary"
          small
        >
          ({{item.users_count}}) Users
        </v-btn>
        <v-btn outlined @click="editItem(item)" class="mx-1" small>
          <v-icon small>mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn outlined @click="remove(item)" class="mx-1" small>
          <v-icon small>mdi-delete-outline</v-icon>
          Remove
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      top
      :color="color"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <confirm-box ref="confirmBox"></confirm-box>

  </layout-default>
</template>

<script>

import layoutDefault from '../layouts/default'
import axios from '../plugins/axios';
import configFile from '../config'
import hasValidation from '../mixins/hasValidation'
import confirmBox from '../components/confirm-box'

export default {
  mixins: [hasValidation],
  components: {layoutDefault, confirmBox},
  data: () => ({
    configFile: configFile,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Company Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      { text: 'Description', value: 'description' },
      { text: 'Company Folder', value: 'folder_path' },
      { text: 'State', value: 'state', width: 220 },
      { text: '', value: 'actions', align: 'end', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      description: '',
      folder_path: ''
    },
    defaultItem: {
      name: '',
      description: '',
      folder_path: ''
    },
    valid: true,
    snackbar: false,
    text: '',
    state: ['Active', 'Inactive', 'On Hold'],
    color: ''
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Company' : 'Edit Company'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    this.initialize()
  },

  methods: {
    remove(item) {
      this.$refs.confirmBox.open('Remove Company', 'Are you sure?', {color: 'primary'})
        .then(() => {
          axios.delete('/companies/' + item.id)
            .then(res => {
              let index = this.desserts.findIndex(i => i.id === item.id)
              this.desserts.splice(index, 1)
            })
        })
    },

    updateState(item) {
      axios.patch('/companies/' + item.id, {state: item.state})
        .then(res => {
          this.text = 'Successfully updated'
          this.color = 'success'
          this.snackbar = true
        })
        .catch(err => {
          this.text = 'Failed updated'
          this.color = 'error'
          this.snackbar = true
        })
    },

    initialize () {
      axios.get(this.configFile.api + '/companies').then(res => {
        this.desserts = res.data
      })
    },

    editItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if(this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          let editedItem = this.editedItem
          let editedIndex = this.editedIndex
          axios.put('/companies/' + this.editedItem.id , this.editedItem)
            .then(res => {
              Object.assign(this.desserts[editedIndex], editedItem)
            })
        } else {
          let editedItem = this.editedItem
          axios.post('/companies', this.editedItem)
            .then(res => {
              editedItem.id = res.data.data.id
              editedItem.users_count = 0;
              this.desserts.push(editedItem)
            })
            .catch(err => {
              this.snackbar = true
              this.color = 'error'
              if(err.response.data.errors.name) {
                this.text = err.response.data.errors.name
              }
              else if(err.response.data.errors.folder_path) {
                this.text = err.response.data.errors.folder_path
              }
              else {
                this.text = err.response.data.errors
              }
            })
        }
        this.close()
      }
    },
  }
}

</script>

<style>
  /*.company .v-input__slot {
    width: 120px!important;
  }*/
</style>
