import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-default',[_c(VRow,{attrs:{"justify":"center"}},[_c(VCardTitle,[_vm._v("Notifications")]),_c(VExpansionPanels,{attrs:{"tile":""}},_vm._l((_vm.messages),function(message,i){return _c(VExpansionPanel,{key:i,attrs:{"hide-actions":""},on:{"click":function($event){message.status = 1}}},[_c(VExpansionPanelHeader,[_c(VRow,{staticClass:"spacer",attrs:{"align":"center","no-gutters":""}},[_c(VCol,{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c(VAvatar,{attrs:{"size":"36px"}},[_c(VIcon,{attrs:{"color":!message.status ? 'primary' : ''},domProps:{"textContent":_vm._s(message.status ? 'mdi-email-open' : 'mdi-email')}})],1)],1),_c(VCol,{staticClass:"hidden-xs-only"},[_c('span',{domProps:{"innerHTML":_vm._s(message.name)}})])],1)],1),_c(VExpansionPanelContent,[_c(VDivider),_c(VCardText,{domProps:{"textContent":_vm._s(_vm.lorem)}})],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }