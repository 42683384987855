<template>
  <layout-default>
<!--    <input type="file" @change="select">-->
<!--    <v-progress-linear :value="progress"></v-progress-linear>-->

    <v-card>
      <v-card-title>
<!--        <v-breadcrumbs-->
<!--          :items="items"-->
<!--          divider="/"-->
<!--          class="pa-1"-->
<!--          @click="loadFiles"-->
<!--        ></v-breadcrumbs>-->
        <template v-for="(item, i) in items">
          <span @click="loadFiles(item)">{{item.text}}</span><span v-if="items.length > i + 1" class="px-2">/</span>
        </template>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col v-for="file in files" :key="file.id" cols="12">
            <div @click="file.mimeType === 'application/vnd.google-apps.folder' && loadFiles(file)">
              <v-icon v-if="file.mimeType === 'application/vnd.google-apps.folder'">mdi-folder</v-icon>
              <v-icon v-else @click="download(file)">mdi-download</v-icon>
              {{file.name}}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </layout-default>
</template>

<script>

import layoutDefault from '../layouts/default'
import axios from '../plugins/axios'

export default {
  components: {layoutDefault},

  created() {
    this.getFolder()
  },

  watch: {
    chunks(n, o) {
      if (n.length > 0) {
        this.upload();
      }
    }
  },

  data() {
    return {
      file: {size: null},
      chunks: [],
      uploaded: 0,
      folders: [],
      files: [],
      items: []
    };
  },

  computed: {

    progress() {
      return Math.floor((this.uploaded * 100) / this.file.size);
    },
    formData() {
      let formData = new FormData;

      formData.set('is_last', this.chunks.length === 1);
      formData.set('file', this.chunks[0], `${this.file.name}.part`);

      return formData;
    },
    config() {
      return {
        method: 'POST',
        data: this.formData,
        url: 'http://localhost:8000/api/upload',
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        onUploadProgress: event => {
          this.uploaded += event.loaded;
        }
      };
    }
  },

  methods: {
    download(data) {
      axios.get('http://localhost:8000/api/google-drive-download/' + data.id, { responseType: 'blob' }).then(res => {

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([res.data]), {type:res.headers['content-type']});
        link.setAttribute('download', data.name);
        document.body.appendChild(link);
        link.click();

      })
    },

    loadFiles(data) {
      axios.get('http://localhost:8000/api/google-drive-files/' + data.id)
        .then(res => {
          this.files = res.data

          let check = this.items.findIndex(i => i.id === data.id)
          if(check < 0) {
            this.items.push({text: data.name, id: data.id})
          }
          else {
            this.items.splice(check + 1, 10)
          }
        })
    },
    getFolder() {
      axios.get('http://localhost:8000/api/google-drive-folders')
        .then(res => {
          this.folders = res.data

          this.loadFiles(res.data[0])
        })
    },
    select(event) {
      this.file = event.target.files.item(0);
      this.createChunks();
    },
    upload() {
      axios(this.config).then(response => {
        this.chunks.shift();
      }).catch(error => {});
    },
    createChunks() {
      let size = 2000000, chunks = Math.ceil(this.file.size / size);
      for (let i = 0; i < chunks; i++) {
        this.chunks.push(this.file.slice(
          i * size, Math.min(i * size + size, this.file.size), this.file.type
        ));
      }
    }
  }
}

</script>
