<template>
  <layout-default>
    <v-data-table
      :headers="headers"
      :items="desserts"
      sort-by="calories"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>{{company.name}} Users</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                New User
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                    <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.name"
                        label="Full Name"
                        :rules="[rules.required, rules.fullName]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                        :rules="[rules.required, rules.email]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.client_id"
                        label="Client ID"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.password"
                        label="Password"
                        :rules="editedIndex < 0  ? [rules.required, rules.password] : [rules.password]"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        @click:append="show2 = !show2"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="editedItem.c_password"
                        label="Re-Password"
                        :rules="editedIndex < 0  ? [rules.required, rules.password] : [rules.password]"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="editedItem.password">
                      <v-switch
                        v-model="sendEmail"
                        :label="editedItem.id && editedItem.id != ''
                        ? 'Send Client Email Reset Client Password'
                        : 'Sent Client Email Create Account'"
                      >
                      </v-switch>
                    </v-col>
                  </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.attempt="{ item }">
        <v-tooltip bottom v-if="item.attempt < 4">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="lock(item)" v-bind="attrs" v-on="on">
              <v-icon>mdi-lock-open-outline</v-icon>
            </v-btn>
            <span>Lock account</span>
          </template>
        </v-tooltip>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="unlock(item)" v-bind="attrs" v-on="on">
              <v-icon color="error">mdi-lock-outline</v-icon>
            </v-btn>
          </template>
          <span>Unlock account</span>
        </v-tooltip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn small outlined class="mx-1" @click="addPermission(item)">
          <v-icon small>mdi-account-lock-outline</v-icon>Permission
        </v-btn>
        <v-btn small outlined class="mx-1" @click="addAccess(item)">
          <v-icon small>mdi-folder-lock-open-outline</v-icon>Access
        </v-btn>
        <v-btn small outlined @click="editItem(item)" class="mx-1">
          <v-icon small>mdi-pencil</v-icon>
          Edit
        </v-btn>
<!--        <v-btn small outlined @click="deleteItem(item)" class="mx-1" disabled>-->
<!--          <v-icon small>mdi-delete</v-icon>-->
<!--          Remove-->
<!--        </v-btn>-->
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogPermission"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Select Permission
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row justify="space-between" v-if="editedItem">
            <v-col cols="auto" v-for="(permission, i) in permissions">
              <v-switch
                v-model="editedItem.permissions[i]"
                :label="permission.name"
                :value="permission.slug"
                hide-details
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="close"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click="save('permissions')"
          >
            Save
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAccess"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Select Access
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>

          <v-row>
            <v-col cols="6">
              <v-checkbox
                label="Select AlL"
                @change="selectAll(editedItem, $event)"
                hide-details
                class="ml-2"
              ></v-checkbox>
              <v-treeview
                dense
                :items="folders"
                return-object
                v-model="editedItem.access"
                selection-type="independent"
                selectable
                item-text="real_name"
                open-all
              >
                <template v-slot:prepend="{ item, open }">
                  <v-icon>
                    {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                  </v-icon>
                </template>
              </v-treeview>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="6">
              <template v-if="!editedItem.access.length">
                No nodes selected.
              </template>
              <template v-else>
                <div
                  v-for="node in editedItem.access"
                  :key="node.id"
                >
                  - {{ node.real_name }}
                </div>
              </template>
            </v-col>
          </v-row>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="close"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click="save('access')"
          >
            Save
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      top
      color="error"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <confirm-box ref="confirmBox"></confirm-box>
  </layout-default>
</template>

<script>

import layoutDefault from '../layouts/default'
import axios from '../plugins/axios';
import configFile from '../config'
import hasValidation from '../mixins/hasValidation'
import confirmBox from '../components/confirm-box'

export default {
  mixins: [hasValidation],
  components: {layoutDefault, confirmBox},
  data: () => ({
    configFile: configFile,
    dialogAccess: false,
    dialogPermission: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Full Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      { text: 'Email', value: 'email' },
      { text: 'Client ID', value: 'client_id' },
      { text: 'State', value: 'attempt', width: 60, sortable: false, align: 'center' },
      { text: '', value: 'actions', align: 'end', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: '',
      name: '',
      email: '',
      client_id: '',
      access: [],
      permissions: [],
      password: '',
      c_password: ''
    },
    defaultItem: {
      name: '',
      email: '',
      client_id: '',
      access: [],
      permissions: [],
      password: '',
      c_password: ''
    },
    permissions: [
      {name: 'Read only', slug: 'read-only'},
      {name: 'Upload File', slug: 'upload-file'},
      {name: 'Delete File', slug: 'delete-file'}
    ],
    selectedPermissions: [],
    items: ['foo', 'bar', 'fizz', 'buzz'],
    values: ['foo', 'bar'],
    folders: [],
    loading: false,
    selection: [{real_name: 'files'}],
    all: false,
    company: '',
    valid: true,
    show1: false,
    show2: false,
    snackbar: false,
    text: '',
    sendEmail: false
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New User' : 'Edit User'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },

  created () {
    this.initialize()
    this.getFolders()
  },

  methods: {
    lock(item) {
      if(item.attempt > 4) return
      this.$refs.confirmBox.open('Lock User Account', 'Are you sure?', {color: 'primary'})
        .then(() => {
          axios.patch('/users/' + item.id, {
            params: {
              attempt: 4
            }
          }).then(res => {
            item.attempt = 4
          })
        })
    },
    unlock(item) {
      if(item.attempt < 4) return
      this.$refs.confirmBox.open('Unlock user account', 'Are you sure?', {color: 'primary'})
        .then(() => {
          axios.patch('/users/' + item.id, {
            params: {
              attempt: 1
            }
          }).then(res => {
            item.attempt = 1
          })
        })
    },
    selectAll(editItem, e) {
      editItem.access = []

      let selectRecursion = (folders) => {
        folders.forEach(item => {
          editItem.access.push(item)
          if(item.children.length) selectRecursion(item.children)
        })
      }

      if(e) {
        selectRecursion(this.folders)
      }
      else {
        editItem.access = []
      }

    },

    getFolders(path = null) {
      this.loading = true
      let company_id = this.$route.params.id
      axios.get(this.configFile.api + '/folders2?company_id=' + company_id )
        .then(res => {
          this.folders = res.data.children
        })
        .finally(() => {
          this.loading = false
        })
    },
    initialize () {
      axios.get(this.configFile.api + '/companies/' + this.$route.params.id + '/users')
        .then(res => {
          this.desserts = res.data.users
          this.company = res.data
          delete this.company.users
        })
    },

    editItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    addPermission(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogPermission = true
    },

    addAccess(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogAccess = true
    },

    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.dialogAccess = false
      this.dialogPermission = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save (type = null) {
      if(type == 'access') {
        this.editedItem.access = this.editedItem.access.map(i => {
          return {
            id: i.id,
            real_name: i.real_name
          }
        })
        axios.post(this.configFile.api + '/companies/' + this.$route.params.id + '/users/' + this.editedItem.id + '/access', this.editedItem)
          .then(res => {
            Object.assign(this.desserts[this.editedIndex], this.editedItem)
            this.close()
          })
      }
      if(type == 'permissions') {
        axios.post(this.configFile.api + '/companies/' + this.$route.params.id + '/users/' + this.editedItem.id + '/permissions', this.editedItem)
        .then(res => {
          Object.assign(this.desserts[this.editedIndex], this.editedItem)
          this.close()
        })
      }

      if(this.$refs.form && this.$refs.form.validate()) {

        if(this.sendEmail) this.editedItem.sendEmail = this.sendEmail
        else delete this.editedItem.sendEmail

        if (this.editedIndex > -1) {
          let editedItem = this.editedItem
          let editedIndex = this.editedIndex
          axios.put('/users/' + this.editedItem.id , this.editedItem)
            .then(res => {
              Object.assign(this.desserts[editedIndex], editedItem)
            })
            .catch(err => {
              this.snackbar = true
              this.text = err.response.data.errorInfo.join(', ')
            })
        } else {
          let _editedItem = this.editedItem
          axios.post(this.configFile.api + '/companies/' + this.company.id + '/users', this.editedItem)
            .then(res => {
              _editedItem.id = res.data.data.id
              _editedItem.attempt = 1
              this.desserts.push(_editedItem)
            })
            .catch(err => {
              this.snackbar = true
              this.text = err.response.data.errorInfo.join(', ')
            })
        }
        this.close()
      }
    },
  }
}

</script>
