<template>
  <layout-default>
    <v-data-table
    :headers="headers"
    :items="desserts"
    sort-by="calories"
    class="elevation-1"
    :search="search"
    dense
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>My Files</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-text-field
          label="Search"
          outlined
          dense
          hide-details
          clearable
          v-model="search"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
        <v-autocomplete
          label="Select Category"
          dense
          hide-details
          outlined
          class="px-2"
          :items="categories"
          @change="selectCategory($event)"
          clearable
        >
        </v-autocomplete>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              New File
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Name"
                      outlined
                      dense
                      prepend-icon="mdi-format-title"
                    ></v-text-field>
                    <v-text-field
                      v-model="editedItem.type"
                      label="Type"
                      outlined
                      dense
                      prepend-icon="mdi-file-chart-check-outline"
                    ></v-text-field>
                    <v-select
                      v-model="editedItem.category"
                      label="Category"
                      :items="categories"
                      outlined
                      dense
                      prepend-icon="mdi-shape-outline"
                    ></v-select>
                    <v-file-input
                      @change="select"
                      label="File input"
                      type="file"
                      outlined
                      dense
                      show-size
                    ></v-file-input>
                    <v-progress-linear :value="progress"></v-progress-linear>
<!--                    <input type="file" @change="select">-->
<!--                    <v-progress-linear :value="progress"></v-progress-linear>-->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                dark
                @click="save"
              >
                Save
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
      >
        mdi-download
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>
    <template v-slot:item.type="{ item }">
      <v-icon v-if="item.type === 'pdf'" color="red">mdi-file-pdf-box</v-icon>
      <v-icon v-else-if="item.type === 'word'" color="primary">mdi-file-word</v-icon>
      <v-icon v-else-if="item.type === 'img'" color="success">mdi-image-album</v-icon>
      <v-icon v-else>mdi-paperclip</v-icon>
    </template>
  </v-data-table>
  </layout-default>
</template>

<script>

import layoutDefault from '../layouts/default'
import axios from '../plugins/axios';

export default {
  components: {layoutDefault},
  data: () => ({
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: 'Type',
        align: 'start',
        sortable: true,
        value: 'type',
        width: '80px'
      },
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      { text: 'File Size', value: 'size' },
      { text: 'Category', value: 'category' },
      { text: 'Upload Date', value: 'created_at' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'end' },
    ],
    desserts: [],
    dessertsImp: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      type: '',
      size: '',
      category: '',
      created_at: ''
    },
    defaultItem: {
      name: '',
      type: '',
      size: '',
      category: '',
      created_at: ''
    },

    file: {size: null},
    chunks: [],
    uploaded: 0,
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    categories() {
      return this.dessertsImp.map(i => i.category)
    },
    config() {
      return {
        method: 'POST',
        data: this.formData,
        url: 'http://localhost:8000/api/upload',
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        onUploadProgress: event => {
          this.uploaded += event.loaded;
        }
      };
    },
    progress() {
      return Math.floor((this.uploaded * 100) / this.file.size);
    },
    formData() {
      let formData = new FormData;

      formData.set('is_last', this.chunks.length === 1);
      formData.set('file', this.chunks[0], `${this.file.name}.part`);

      return formData;
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    chunks(n, o) {
      if (n.length > 0) {
        this.upload();
      }
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    upload() {
      axios(this.config).then(response => {
        this.chunks.shift();
      }).catch(error => {});
    },
    select(event) {
      this.file = event;

      this.editedItem.name = this.file.name
      this.editedItem.type = this.file.name.split('.')[1]
      this.editedItem.size = (this.file.size / 1024 / 1024).toFixed(2) + 'MB'
      this.editedItem.created_at = new Date()

      this.createChunks();
    },
    createChunks() {
      let size = 2000000, chunks = Math.ceil(this.file.size / size);
      for (let i = 0; i < chunks; i++) {
        this.chunks.push(this.file.slice(
          i * size, Math.min(i * size + size, this.file.size), this.file.type
        ));
      }
    },

    selectCategory(category) {
      if(category) {
        this.desserts = this.dessertsImp.filter(i => i.category == category)
      }
      else {
        this.desserts = this.dessertsImp
      }
    },

    initialize () {
      this.desserts = [
        {
          name: 'Invoice Q1',
          type: 'pdf',
          size: '1.6 KB',
          created_at: '29 May 2022',
          category: 'Invoice'
        },
        {
          name: 'Invoice Q2',
          type: 'word',
          size: '1.2 MB',
          created_at: '20 Avg 2022',
          category: 'Invoice'
        },
        {
          name: 'Invoice Q3',
          type: 'word',
          size: '2.7 KB',
          created_at: '29 Avg 2022',
          category: 'Invoice'
        },
        {
          name: 'Invoice Q4',
          type: 'word',
          size: '10.1 KB',
          created_at: '29 Avg 2022',
          category: 'Invoice'
        },
        {
          name: 'Invoice January',
          type: 'img',
          size: '2.2 Mb',
          created_at: '29 Avg 2022',
          category: 'Invoice'
        },
        {
          name: 'Invoice February',
          type: 'img',
          size: '1.4 KB',
          created_at: '29 Avg 2022',
          category: 'Invoice'
        },
        {
          name: 'Invoice March',
          type: 'file',
          size: '1.2 KB',
          created_at: '29 Jan 2021',
          category: 'Invoice'
        },
        {
          name: 'Invoice April',
          type: 'pdf',
          size: '1.6 KB',
          created_at: '29 May 2022',
          category: 'Invoice'
        },
        {
          name: 'Invoice May',
          type: 'word',
          size: '1.2 MB',
          created_at: '20 Avg 2022',
          category: 'Invoice'
        },
        {
          name: 'Invoice June',
          type: 'word',
          size: '2.7 KB',
          created_at: '29 Avg 2022',
          category: 'Invoice'
        },
        {
          name: 'Annex 1',
          type: 'word',
          size: '10.1 KB',
          created_at: '29 Avg 2022',
          category: 'Annex'
        },
        {
          name: 'Invoice July',
          type: 'img',
          size: '2.2 Mb',
          created_at: '29 Avg 2022',
          category: 'Invoice'
        },
        {
          name: 'Contract 2022',
          type: 'img',
          size: '1.4 KB',
          created_at: '29 Avg 2022',
          category: 'Contract'
        },
        {
          name: 'Report Q1',
          type: 'file',
          size: '1.2 KB',
          created_at: '29 Jan 2021',
          category: 'Report'
        }
      ]
      this.dessertsImp = [...this.desserts]
    },

    editItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem)
      } else {
        this.desserts.push(this.editedItem)
      }
      this.close()
    },
  },
}
</script>
