<template>
  <layout-default>
    <v-row>
      <v-col cols="12" sm="12">
        <canvas-chart v-if="item" :data="item"></canvas-chart>
      </v-col>
      <v-col cols="12" sm="6">
        <canvas-chart :data="item2"></canvas-chart>
      </v-col>
      <v-col cols="12" sm="6">
        <canvas-chart :data="item3"></canvas-chart>
      </v-col>
    </v-row>
  </layout-default>
</template>

<script>

import layoutDefault from '../layouts/default'
import CanvasChart from "../components/canvas-chart"
import axios from "../plugins/axios";

export default {
  components: {layoutDefault, CanvasChart},
  data() {
    return {
      item: null,
      item2: {
        "id": "RWnpNgM2EA7VrGdJ2",
        "question": "Would you be interested in attending an event on Multicloud-as-a-Service with industry leading speakers?",
        "stats": {
          "Yes": { "count": 53, "percent": 88.3, "selected": true },
          "No": { "count": 7, "percent": 11.7, "selected": true },
          "No2": { "count": 7, "percent": 11.7, "selected": true },
          "No3": { "count": 7, "percent": 11.7, "selected": true },
          "sample": 60
        },
        "qkind": "radio"
      },
      item3: {
        "id": "RWnpNgM2EA7VrGdJ3",
        "question": "Would you be interested in attending an event on Multicloud-as-a-Service with industry leading speakers?",
        "stats": {
          "Yes": { "count": 53, "percent": 88.3, "selected": true },
          "No": { "count": 7, "percent": 11.7, "selected": true },
          "No2": { "count": 7, "percent": 11.7, "selected": true },
          "No3": { "count": 7, "percent": 11.7, "selected": true },
          "No4": { "count": 7, "percent": 11.7, "selected": true },
          "No5": { "count": 7, "percent": 11.7, "selected": true },
          "No6": { "count": 7, "percent": 11.7, "selected": true },
          "sample": 60
        },
        "qkind": "radio"
      }
    }
  },
  created() {
    axios.get('/analytics/user/' + this.$route.params.id)
      .then(res => {
        this.item = res.data.arr3
      })
  }
}

</script>
