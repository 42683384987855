import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{style:({ zIndex: _vm.options.zIndex }),attrs:{"max-width":_vm.options.width,"persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"confirm-box"},[_c(VCardTitle,{staticClass:"py-2",class:_vm.options.color},[_c('span',{staticClass:"white--text subheading font-weight-medium"},[_vm._v(_vm._s(_vm.title))])]),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(!!_vm.message),expression:"!!message"}],domProps:{"innerHTML":_vm._s(_vm.message)}}),_c(VDivider),_c(VCardActions,{staticClass:"py-0"},[_c(VSpacer),(!_vm.onlyConfirm)?_c(VBtn,{attrs:{"color":"grey","text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v("No")]):_vm._e(),_c(VBtn,{attrs:{"color":"primary darken-1","text":""},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.onlyConfirm ? "OK" : 'Yes'))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }