<template>
    <canvas :id="'ITWBar' + data.id" width="100%"></canvas>
</template>

<script>
import Chart from 'chart.js';
import 'chartjs-plugin-labels';

export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    mounted() {
        this.graph()
    },
    methods: {
        randomHex() {
            return Math.floor(Math.random() * 255)
        },
        graph() {
            let type = 'line';
            let labelType = 'one line';
            let value = 'count';
            const options = Object.keys(this.data.stats).filter(key => {
                return key !== 'sample' && this.data.stats[key].selected
            });
            if (options.length < 4) {
                type = 'doughnut';
                value = 'percent';
            } else if (options.length < 6) {
                type = 'pie';
                value = 'percent';
            } else if (options.length < 10) {
                type = 'line';
                labelType = 'two line';
                value = 'percent';
            }

            let ctx = document.getElementById('ITWBar' + this.data.id).getContext('2d');
            let backgroundColor = [];
            let borderColor = [];
            options.forEach(() => {
                const colors = [this.randomHex(), this.randomHex(), this.randomHex()];
                backgroundColor.push(`rgb(${colors.join(',')}, 1)`);
                borderColor.push(`rgb(${colors.join(',')})`);
            });

            borderColor = type === 'line' ? '#2a8bc7' : borderColor

            let myChart = new Chart(ctx, {
                type,
                data: {
                    labels: options.map(item => {
                        let text = item;
                        if (labelType === 'two line') {
                            const split = item.split(' ');
                            text = [split[0]];
                            split.forEach((word, i) => {
                                if (i) {
                                    const lastWord = text.slice(-1)[0];
                                    if (lastWord.length + word.length > 20) {
                                        text.push(word)
                                    } else {
                                        const index = text.indexOf(lastWord);
                                        text[index] += ' ' + word
                                    }
                                }
                            })
                            text = text.join('!#')
                        }
                        return text.split("!#");
                    }),
                    datasets: [{
                        data: options.reduce((arr, key) => {
                            const property = value === 'count' ? 'count' : 'percent';
                            arr.push(this.data.stats[key][property]);
                            return arr;
                        }, []),
                        backgroundColor,
                        borderColor: ['pie', 'doughnut'].includes(type) ? 'white' : borderColor,
                        borderWidth: type === 'line' ? 3 : 1,
                        fill: type === 'line' ? false : true
                    }]
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    legend: {
                        display: ['pie', 'doughnut'].includes(type)
                    },
                    plugins: {
                        labels: {
                            render: type === 'bar' ? 'count' : 'percentage',
                            fontColor: 'white',
                            precision: 2
                        }
                    },
                }
            });
        }
    }
}
</script>
