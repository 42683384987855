<template>
  <layout-default>
    <v-row>
      <v-col cols="12" sm="2">
        Select filter:
        <template v-for="n in [2010, 2011, 2022]">
          <v-switch
            v-model="selectedYears"
            inset
            :label="n"
            :value="n"
            @change="load"
            hide-details
          ></v-switch>
        </template>
      </v-col>
      <v-col cols="12" sm="10">
        <bar :chart-data="chartData"></bar>
      </v-col>
    </v-row>
  </layout-default>
</template>

<script>

import layoutDefault from '../layouts/default'
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)


export default {
  components: {layoutDefault, Bar},
  data() {
    return {
      chartData: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      selectedYears: [2010],
      datasets2: [
        {
          label: 2010,
          backgroundColor: 'yellow',
          data: [40, 20, 12, 23, 10, 34, 39, 23, 40, 20, 12, 11]
        },
        {
          label: 2011,
          backgroundColor: '#062541',
          data: [40, 20, 12, 23, 10, 34, 39, 23, 40, 20, 12, 11]
        },
        {
          label: 2022,
          backgroundColor: '#2fa1e5',
          data: [40, 20, 58, 39, 10, 40, 39, 80, 40, 20, 12, 11]
        }
      ]
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.chartData.datasets = this.datasets2.filter(i => this.selectedYears.includes(i.label))
    }
  }
}

</script>
