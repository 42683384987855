<template>
  <layout-login>

    <v-col cols="12" class="mt-15">
      <v-row justify="center" class="pt-15">
        <v-col cols="12" sm="10" md="10" lg="10" xl="8">
          <v-row>
            <v-col cols="12" sm="6">
                <div>
                  <h1 class="white--text mb-4">
                    Welcome to Samuel Client.
                  </h1>
                  <p class="white--text font-weight-light" style="font-size: 22px">
                    Introducing a new way to work with our<br>
                    clients through a dedicated web Portal.
                  </p>
                </div>

            </v-col>
            <v-col cols="12" sm="6">
              <div class="white--text text-h4 mb-4" align="center">Enter your SC Login Details</div>
              <div style="background-color: #02192f; padding: 32px; margin-bottom: 32px">
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="pb-2"
                >
                  <v-text-field
                    label="User ID"
                    color="white"
                    v-model="email"
                    dark
                    :rules="nameRules"
                    required
                  >
                  </v-text-field>
                  <v-text-field
                    label="Password"
                    type="password"
                    color="white"
                    v-model="password"
                    dark
                    :rules="emailRules"
                    required
                  >
                  </v-text-field>
                  <v-checkbox
                    v-model="checkbox"
                    label="Remember my username"
                    color="blue"
                    dark
                  ></v-checkbox>

                  <v-btn
                    color="blue-grey lighten-1"
                    class="white--text"
                    style="padding-left: 70px; padding-right: 70px"
                    outlined
                    @click="login"
                    :loading="loading"
                  >
                    Log in
                  </v-btn>
                </v-form>
<!--                <a href="#" class="white&#45;&#45;text">Forgot Password</a>-->
                <router-link :to="{name: 'privacy'}">Privacy</router-link>
                <span class="px-2"></span>
                <router-link :to="{name: 'help'}">Need Help</router-link>
<!--                <a href="#" class="white&#45;&#45;text">Heed Help</a>-->
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-snackbar
      v-model="snackbar"
      top
      color="error"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card>
        <v-card-title>Two factor authentication</v-card-title>
        <v-card-text>
          <v-text-field
            label="Verification Code"
            outlined
            dense
            v-model="code"
          >
          </v-text-field>
          <div>
            Verification code has sent on your email address
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialog = false">Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="login" :disabled="loading" :loading="loading">ReSend</v-btn>
          <v-btn color="primary" @click="verify" :disabled="loading" :loading="loading">Verify</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </layout-login>
</template>

<script>

import layoutLogin from '../layouts/login'
import axios from '../plugins/axios';
import configFile from '../config';
import store from "../store";
import analyticsServices from "../services/analytics";

export default {
  components: {layoutLogin},
  data() {
    return {
      valid: true,
      email: '',
      nameRules: [
        v => !!v || 'User ID is required',
        //v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      password: '',
      emailRules: [
        v => !!v || 'Password is required',
        //v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      checkbox: false,
      configFile: configFile,
      snackbar: false,
      text: '',
      loading: false,
      dialog: false,
      code: '',
      attempt: null
    }
  },
  created() {
    this.$store.dispatch('GET_PROFILE').then(res => {
      let slug = this.$store.state.user.company.name.replaceAll(' ', '-').toLowerCase()
      this.$router.replace({name: 'slug-home', params: {slug}})
    })
  },
  methods: {
    verify() {
      this.loading = true
      this.$store.dispatch('TWO_FACTOR_AUTH', {
        email: this.email,
        password: this.password,
        two_factor_code: this.code
      }).then(res => {
        analyticsServices.store({type: 'login', params: []})
        this.$router.push({name: 'slug-home', params: {slug: this.$store.state.user.company.name.toLowerCase()}})
      }).catch(err => {
        this.text = 'Verification code is expired'
        this.snackbar = true
      }).finally(() => {
        this.loading = false
      })
    },
    login() {
      if(this.$refs.form.validate()) {
        this.loading = true
        this.$store.dispatch('LOGIN_USER', {
          email: this.email,
          password: this.password
        }).then(res => {
            //analyticsServices.store({type: 'login', params: []})
            this.dialog = true
            //this.$router.push({name: 'slug-home', params: {slug: this.$store.state.user.company.name.toLowerCase()}})
        }).catch(err => {
          if (err.response) {
            this.text = err.response.data.error
            this.attempt = err.response.data.attempt
          } else if (err.request) {
            this.text = err.request
          } else {
            this.text = 'Client ID and/or password is incorrect'
          }
          this.snackbar = true
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }
}

</script>

<style>



a {
  color: white!important;
}

</style>
