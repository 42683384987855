<template>
  <layout-default>
    <v-card>
      <v-card-title>
        Users
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="users"
        :search="search"
      >
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                :to="{
                  name: 'slug-analytics-user-id',
                  params: { slug: $store.state.user.company.name, id: item.id }
                }"
              >
                <v-icon small>mdi-file-chart-outline</v-icon>
              </v-btn>
            </template>
            <span>Analytics</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </layout-default>
</template>

<script>

import layoutDefault from '../layouts/default'
import axios from "../plugins/axios";

export default {
  components: {layoutDefault},
  data () {
    return {
      search: '',
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        { text: 'Client ID', value: 'client_id' },
        { text: 'Email', value: 'email' },
        { text: 'Company', value: 'company.name' },
        { text: 'Action', value: 'action' }
      ],
      users: []
    }
  },
  created() {
    axios.get('/users')
      .then(res => {
        this.users = res.data
      })
  }
}

</script>
