<template>
  <layout-default>
    <v-row>
      <v-col cols="12" sm="6">
        <v-row>
          <v-col cols="12">
            <v-toolbar dense class="mb-2" elevation="1">Total Users: {{item.total}}</v-toolbar>
            <v-card>
              <v-card-text>
                <canvas-chart v-if="item" :data="item"></canvas-chart>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-toolbar dense class="mb-2" elevation="1">Total Users: {{item2.total}}</v-toolbar>
            <v-card>
              <v-card-text>
                <canvas-chart v-if="item2" :data="item2"></canvas-chart>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card>
          <v-card-text>
            <v-card-title>Sessions by country</v-card-title>
            <v-list-item v-for="(el, i) in item3" two-line :key="i">
              <v-list-item-content>
                <v-list-item-title>{{el.country}}</v-list-item-title>
                <v-list-item-subtitle>{{el.city}}</v-list-item-subtitle>
                <v-list-item-subtitle>IP: {{el.ip}} Count: {{el.count}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </layout-default>
</template>

<script>

import layoutDefault from '../layouts/default'
import CanvasChart from "../components/canvas-chart"
import axios from "../plugins/axios";

export default {
  components: {layoutDefault, CanvasChart},
  data() {
    return {
      item: null,
      item2: null,
      item3: null
    }
  },
  created() {
    axios.get('/analytics/active-week').then(res => {
      this.item = res.data
    })

    axios.get('/analytics/active-company')
      .then(res => {
        this.item2 = res.data
      })

    axios.get('/analytics/active-country')
      .then(res => {
        this.item3 = res.data
      })
  }
}

</script>
