import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-default',[_c(VCard,[_c(VCardTitle,[_vm._v(" Users "),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{attrs:{"dense":"","headers":_vm.headers,"items":_vm.users,"search":_vm.search},scopedSlots:_vm._u([{key:"item.action",fn:function({ item }){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","to":{
                name: 'slug-analytics-user-id',
                params: { slug: _vm.$store.state.user.company.name, id: item.id }
              }}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-file-chart-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Analytics")])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }