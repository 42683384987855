<template>
  <layout-default>
    <v-row no-gutters justify="space-between">
      <div style="font-size: 32px" class="font-weight-bold">Invoices</div>
      <v-btn color="#062541" dark>Add new</v-btn>
    </v-row>
    <template v-for="invoice in invoices">
      <v-card class="my-3" color="#062541" @click="dialog = true">
        <v-card-text>
          <v-row no-gutters>
            <v-col class="white--text title" cols="2">{{invoice.id}}</v-col>
            <v-col class="white--text title">{{invoice.date}}</v-col>
            <v-col class="white--text title">{{invoice.company}}</v-col>
            <v-col class="white--text title">{{invoice.price}}</v-col>
            <v-col class="white--text title">
              <v-menu top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="invoice.status.toLowerCase() == 'pending' ? 'warning' : invoice.status.toLowerCase() == 'paid' ? 'success' : ''"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="width: 120px"
                    outlined
                  >
                    <v-row>
                      <v-col cols="4" class="pa-0">
                        <div style="font-size: 60px">&#183;</div>
                      </v-col>
                      <v-col cols="8" class="pa-0">
                        <v-row justify="start" align="center" class="fill-height" no-gutters>
                          {{invoice.status}}
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, index) in ['pending', 'draft', 'paid']"
                    :key="index"
                    @click="$set(invoice, 'status', item)"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>


            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>



    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="#062541"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Preview</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row style="height: 100vh">
          <iframe
            src="http://localhost:8000/storage/Roosevelt%20Lloyd%20Ashley%20Medina.pdf"
            frameBorder="0"
            scrolling="auto"
            height="100%"
            width="100%"
          ></iframe>
        </v-row>
      </v-card>
    </v-dialog>
  </layout-default>
</template>

<script>

import layoutDefault from '../layouts/default'
import axios from '../plugins/axios';

export default {
  components: {layoutDefault},
  data() {
    return {
      file: '',
      myState: {
        pdf: null,
        currentPage: 1,
        zoom: 1
      },

      pdf: null,
      canvas: null,
      isPageRendering: null,
      pageRenderingQueue: null,
      canvasContext: null,
      totalPages: null,
      currentPageNum: null,

      pdfjs: null,

      invoices: [
        {
          id: '#123123',
          date: '23 Jan 2023',
          company: 'SAMUEL TECHNOLOGIES',
          price: '$ 10.000',
          status: 'Paid'
        },
        {
          id: '#232123',
          date: '23 June 2023',
          company: 'SAMUEL TECHNOLOGIES',
          price: '$ 20.000',
          status: 'Pending'
        },
        {
          id: '#232123',
          date: '23 June 2022',
          company: 'SAMUEL TECHNOLOGIES',
          price: '$ 10.000',
          status: 'Draft'
        },
        {
          id: '#124123',
          date: '23 Jan 2023',
          company: 'SAMUEL TECHNOLOGIES',
          price: '$ 10.000',
          status: 'Paid'
        },
        {
          id: '#232423',
          date: '23 June 2023',
          company: 'SAMUEL TECHNOLOGIES',
          price: '$ 20.000',
          status: 'Pending'
        },
      ],
      dialog: false
    }
  },
  created() {
    axios.get('http://localhost:8000/api/invoice').then(res => {
      this.file = res.data
    })
  },
  methods: {

  },
  mounted() {
    // let recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.0.943/pdf.min.js')
    // document.head.appendChild(recaptchaScript)

  }
}

</script>
