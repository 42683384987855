import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"inspire"}},[_c(VAppBar,{staticStyle:{"background-color":"#062541"},attrs:{"app":"","clipped-left":""}},[_c('router-link',{staticClass:"pt-1",attrs:{"to":{name: 'slug-home', params: {slug: _vm.$store.state.user.company.name}}}},[_c('img',{attrs:{"src":require("../assets/samuel_clients_horizontal_white.png"),"height":"46px"}})]),_c(VRow,{staticClass:"white--text fill-height px-2 title-client",attrs:{"align":"end","no-gutters":""}},[_vm._v(_vm._s(_vm.$store.state.user.company.name))]),_c(VSpacer),_c(VMenu,{attrs:{"bottom":"","min-width":"200px","rounded":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":"","x-large":""}},on),[_c(VAvatar,{attrs:{"color":"#2fa1e5","size":"48"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.initials))])])],1)]}}])},[_c(VCard,[_c(VListItemContent,{staticClass:"justify-center"},[_c('div',{staticClass:"mx-auto text-center"},[_c(VAvatar,{attrs:{"color":"#2fa1e5"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.initials))])]),_c('h3',[_vm._v(_vm._s(_vm.$store.state.user.name))]),_c('h3',{staticClass:"py-1"},[_vm._v("-"+_vm._s(_vm.$store.state.user.type)+"-")]),_c('p',{staticClass:"text-caption mt-1"},[_vm._v(" "+_vm._s(_vm.$store.state.user.email)+" ")]),_c(VDivider,{staticClass:"my-3"}),_c(VBtn,{attrs:{"depressed":"","rounded":"","text":""}},[_vm._v(" Edit Account ")]),_c(VDivider,{staticClass:"my-3"}),_c(VBtn,{attrs:{"depressed":"","rounded":"","text":""},on:{"click":_vm.logout}},[_vm._v(" Logout ")])],1)])],1)],1)],1),_c(VMain,[_c(VContainer,[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }