<template>
  <layout-default>
    <v-row justify="center">
      <v-card-title>Notifications</v-card-title>
      <v-expansion-panels tile>
        <v-expansion-panel
          v-for="(message, i) in messages"
          :key="i"
          hide-actions
          @click="message.status = 1"
        >
          <v-expansion-panel-header>
            <v-row
              align="center"
              class="spacer"
              no-gutters
            >
              <v-col
                cols="auto"
                class="pr-2"
              >
                <v-avatar
                  size="36px"
                >
                  <v-icon
                    :color="!message.status ? 'primary' : ''"
                    v-text="message.status ? 'mdi-email-open' : 'mdi-email'"
                  ></v-icon>
                </v-avatar>
              </v-col>

              <v-col
                class="hidden-xs-only"
              >
                <span v-html="message.name"></span>
              </v-col>

            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-divider></v-divider>
            <v-card-text v-text="lorem"></v-card-text>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </layout-default>
</template>

<script>

import layoutDefault from '../layouts/default'

export default {
  components: {layoutDefault},
  data: () => ({
    messages: [
      {
        color: 'red',
        name: 'The standard Lorem Ipsum passage, used since the 1500s',
        status: 1
      },
      {
        color: 'teal',
        name: 'Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC',
        status: 0
      },
      {
        color: 'teal',
        name: '1914 translation by H. Rackham',
        status: 0
      },
      {
        color: 'red',
        name: 'The standard Lorem Ipsum passage, used since the 1500s',
        status: 1
      },
      {
        color: 'red',
        name: 'The standard Lorem Ipsum passage, used since the 1500s',
        status: 1
      },
      {
        color: 'teal',
        name: 'Section 1.10.33 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC',
        status: 0
      },
      {
        color: 'teal',
        name: '1914 translation by H. Rackham',
        status: 0
      },
    ],
    lorem: 'Lorem ipsum dolor sit amet, at aliquam vivendum vel, everti delicatissimi cu eos. Dico iuvaret debitis mel an, et cum zril menandri. Eum in consul legimus accusam. Ea dico abhorreant duo, quo illum minimum incorrupte no, nostro voluptaria sea eu. Suas eligendi ius at, at nemore equidem est. Sed in error hendrerit, in consul constituam cum.',
  })
}
</script>

<style scoped>
.v-expansion-panel-header {
  padding: 4px 8px!important;
}
</style>
