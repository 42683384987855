import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-default',[_c(VCard,[_c(VCardTitle,[_vm._l((_vm.items),function(item,i){return [_c('span',{on:{"click":function($event){return _vm.loadFiles(item)}}},[_vm._v(_vm._s(item.text))]),(_vm.items.length > i + 1)?_c('span',{staticClass:"px-2"},[_vm._v("/")]):_vm._e()]})],2),_c(VDivider),_c(VCardText,[_c(VRow,_vm._l((_vm.files),function(file){return _c(VCol,{key:file.id,attrs:{"cols":"12"}},[_c('div',{on:{"click":function($event){file.mimeType === 'application/vnd.google-apps.folder' && _vm.loadFiles(file)}}},[(file.mimeType === 'application/vnd.google-apps.folder')?_c(VIcon,[_vm._v("mdi-folder")]):_c(VIcon,{on:{"click":function($event){return _vm.download(file)}}},[_vm._v("mdi-download")]),_vm._v(" "+_vm._s(file.name)+" ")],1)])}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }