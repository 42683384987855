<template>
  <v-row class="pt-4 terms-and-conditions">
    <v-col cols="12">
      <template v-for="item in items">
        <h2>{{item.title}}</h2>
        <div v-html="item.text"></div>
      </template>
      <p>Effective date: March 1, 2023</p>
    </v-col>
  </v-row>
</template>

<script>

export default {
  data() {
    return {
      items: [
        {
          title: 'Samuel Client Portal Agreement and User Policy',
          text: '<p>This Samuel Client Portal Agreement and User Policy (the &quot;Agreement&quot;) is made\n' +
            'by and between the Samuel Group Inc. and its member companies and affiliates\n' +
            '(the &quot;Company&quot;) and each individual, business, organization or other person or\n' +
            'entity (the &quot;Client&quot;) that uses the Samuel Client Portal provided by the Company.\n' +
            'Each time such the Samuel Client Portal is used, an implicit affirmation is given\n' +
            'to accept the following Terms and Conditions.</p>'
        },
        {
          title: 'Purpose',
          text: '<p>The Company provides a voluntary Portal using proprietary software on an Amazon\n' +
            'Web Services (AWS) hosted cloud platform to permit easy and secure electronic access\n' +
            'to specific documents (which may include confidential and sensitive files) created or\n' +
            'maintained by the Company and the Client for use by the Company. The Company has\n' +
            'sole discretion in deciding which files can be viewed or downloaded on the Samuel\n' +
            'Client Portal. The Company is not responsible for the security of any material the Client\n' +
            'downloads or uploads from and to the Samuel Client Portal.</p>'
        },
        {
          title: 'Acceptance of Terms of Use',
          text: '<p>The Company may modify, suspend, discontinue, or restrict the use of any portion of\n' +
            'the Company&#39;s Portal system, including the availability of any part of the content at any\n' +
            'time, without notice or liability. The Company&#39;s Portal system is offered to you\n' +
            'conditional upon your acceptance of the Terms, Conditions, and statements contained\n' +
            'herein and is updated occasionally. You agree to these Terms and Conditions by using\n' +
            'the Samuel Client Portal.</p>'
        },
        {
          title: 'Description of Service',
          text: '<p>The Samuel Client Portal provides secure access to documents and digital files. The\n' +
            'Company&#39;s Portal system provides authorized Clients with access to information\n' +
            'displayed on the Portal for deliveries of documents and communications for their\n' +
            'account only. The information, documents and communications on the Samuel Client\n' +
            'Portal are provided as a convenient resource for Clients. The Samuel Client Portal is\n' +
            'not designed as a means for regular communication. Some documents being\n' +
            'transmitted may only be available for a limited time, after which they will be\n' +
            'automatically deleted.</p>'
        },
        {
          title: 'Service Availability',
          text: '<p>The Client agrees and acknowledges that the Samuel Client Portal is sometimes\n' +
            'unavailable due to regularly scheduled maintenance, service upgrades, or other\n' +
            'mechanical or electronic failures. The Company will use its best efforts to provide 24-\n' +
            'hour daily availability of the Samuel Client Portal. However, the Company makes no\n' +
            'representation or warranty that 24-hour service will be available. The Company shall not\n' +
            'be responsible for any error, omission, interruption, deletion, defect, delay in operation\n' +
            'or transmission, communications line failure, theft or destruction or unauthorized access\n' +
            'to the Samuel Client Portal.</p>' +
            '<p>The Company is not responsible for any problems or technical malfunctions of any\n' +
            'telephone or fibre network or lines, online computer systems, servers or providers,\n' +
            'computer equipment, software, failure of any email to be received by the Company on\n' +
            'account of technical problems or traffic congestion on the internet or at any website, or\n' +
            'any combination thereof, including any injury or damage to your computer or peripherals\n' +
            'related to the use or downloading any materials from the Samuel Client Portal.</p>'
        },
        {
          title: 'User Password and Security',
          text: '<p>Using the Samuel Client Portal and its related services requires using your username\n' +
            'and password, which the Company will provide to the Client. The confidentiality of your\n' +
            'username, password, and account itself is the sole responsibility of the individual user.\n' +
            'Any activities that occur under users&#39; accounts are their responsibility. You agree to\n' +
            'notify the Company immediately of any unauthorized use of accounts or any other\n' +
            'security breach. Using another person&#39;s username and/or password is expressly\n' +
            'prohibited. The Samuel Client Portal uses two-factor authentication at each login to\n' +
            'ensure privacy and security.\n' +
            'You acknowledge that using your username and password, along with the two-factor\n' +
            'authentication, is acceptable security.</p>' +
            '<p>You are solely responsible for (1) authorizing, monitoring, controlling access to and\n' +
            'maintaining strict confidentiality of your username and password, (2) not allowing\n' +
            'another person to use your username or password, (3) any changes or damage that\n' +
            'may be incurred as a result of your neglect to maintain the strict confidentiality of your\n' +
            'username and password, and (4) promptly informing the Company in writing of any\n' +
            'need to deactivate a username due to security concerns or otherwise. The Company is\n' +
            'not liable for any harm related to the misuse or theft of usernames or passwords,\n' +
            'disclosure of usernames or passwords, or your authorization to allow another person or\n' +
            'entity to access and use the Company&#39;s Samuel Client Portal using your username or\n' +
            'password.</p>' +
            '<p>The Company will use its best efforts to secure the Samuel Client Portal from\n' +
            'unauthorized access. The software and hosting for the Samuel Client Portal provide\n' +
            'encryption on communication between the Samuel Client Portal and your device and\n' +
            'also provides other back-end security measures. You agree that you shall not attempt to\n' +
            'disable or circumvent any security features on the client portal or ask any third party to\n' +
            'do so on your behalf. However, you agree that you recognize that a risk still exists,\n' +
            'however minor, that a breach of such security may occur.</p>' +
            '<p>The Company makes no warranty, express or implied, regarding the efficacy of the\n' +
            'security of the Samuel Client Portal and shall never be liable for any actual or\n' +
            'consequential damages, claimed or otherwise, arising from any breach or alleged\n' +
            'breach of security of the client portal.</p>'
        },
        {
          title: 'Termination of User Account and Login Ability',
          text: '<p>To the maximum extent permitted by applicable law, all such information, software,\n' +
            'documents, and communications are provided &quot;as is&quot; without warranty or condition. The\n' +
            'Company hereby disclaims all warranties and conditions concerning such information,\n' +
            'software, documents, and communications, including, without limitation, all implied\n' +
            'warranties or conditions of merchantability, fitness for a particular purpose, title, and\n' +
            'non-infringement.</p>' +
            '<p>To the maximum extent permitted by applicable law, in no event shall the Company be\n' +
            'liable for any direct, indirect, punitive, incidental, special, consequential damages, or\n' +
            'any other damages whatsoever, including without limitation, damages for loss of use,\n' +
            'data, or profits, arising out of or in any way connected with your use or the performance\n' +
            'of the Samuel Client Portal or Portal system, with the delay or inability to use the Portal\n' +
            'or related services, whether based in contract, tort, negligence, strict liability, or\n' +
            'otherwise, even if the Company has been advised of the possibility of damages.</p>'
        },
        {
          title: 'Accuracy of Content and Liability Disclaimer',
          text: '<p>To the maximum extent permitted by applicable law, all such information, software,\n' +
            'documents, and communications are provided &quot;as is&quot; without warranty or condition. The\n' +
            'Company hereby disclaims all warranties and conditions concerning such information,\n' +
            'software, documents, and communications, including, without limitation, all implied\n' +
            'warranties or conditions of merchantability, fitness for a particular purpose, title, and\n' +
            'non-infringement.</p>' +
            '<p>To the maximum extent permitted by applicable law, in no event shall the Company be\n' +
            'liable for any direct, indirect, punitive, incidental, special, consequential damages, or\n' +
            'any other damages whatsoever, including without limitation, damages for loss of use,\n' +
            'data, or profits, arising out of or in any way connected with your use or the performance\n' +
            'of the Samuel Client Portal or Portal system, with the delay or inability to use the Portal\n' +
            'or related services, whether based in contract, tort, negligence, strict liability, or\n' +
            'otherwise, even if the Company has been advised of the possibility of damages.</p>'
        },
        {
          title: 'Indemnification',
          text: '<p>To the extent allowed by applicable law, Client agrees to defend, indemnify and hold\n' +
            'harmless Company and any of its directors, officers, members, shareholders,\n' +
            'employees, contractors and their heirs from and against any and all third-party claims\n' +
            'and all liabilities, assessments, losses, costs or damages resulting from or arising out of\n' +
            'or related to the Client&#39;s use of the Samuel Client Portal, or any breach by the Client of\n' +
            'any Term and Condition of this Agreement. If any such action is brought against any\n' +
            'indemnified party, they shall notify the Client in writing. The Client and/or Associate shall\n' +
            'assume and control the defence and settlement of each such action, including the\n' +
            'employment of counsel and payment of all expenses associated with such action. Any\n' +
            'indemnified party shall have the right to employ separate counsel in any such action\n' +
            'and participate in the defence at their own cost unless otherwise agreed to by Client.</p>'
        },
        {
          title: 'Changes to Terms and Conditions of Use',
          text: '<p>The Company reserves the right to modify the Terms, Conditions, and notices under\n' +
            'which the Portal system is offered. Your continued use of the Company&#39;s Portal system\n' +
            'and the Samuel Client Portal after the postings of any amended Terms and Conditions\n' +
            'shall constitute your Agreement to be bound by any such changes.</p>'
        },
        {
          title: 'Entire Agreement',
          text: '<p>This Agreement is the entire Agreement between the Company and you regarding the\n' +
            'use of the Samuel Client Portal, and all changes must be in writing as an amendment to\n' +
            'this document. Verbal or other communications are not valid until written in this\n' +
            'document. This Agreement does not modify any existing or future service agreements\n' +
            'provided to you by the Company and is not intended to be a service agreement.</p>'
        },
        {
          title: 'Term and Termination',
          text: '<p>The term of this Agreement extends past the life of the Samuel Client Portal and\n' +
            'remains in full force and effect even if access is terminated to you or the Samuel Client\n' +
            'Portal or if you cease to be a Client of the Company. The Company may terminate in\n' +
            'whole or in part the Company&#39;s Portal system or the Samuel Client Portal without notice\n' +
            'or liability. The Company may also choose to use a different third-party software and/or\n' +
            'hosting provider at any time without notice or liability.</p>'
        },
        {
          title: 'Enforceability',
          text: '<p>Should any portion of this Agreement be deemed unlawful, void, or unenforceable, that\n' +
            'part will be deemed severable. It will not affect the validity and enforceability of all\n' +
            'remaining provisions, which will remain in full force and effect. The failure of the\n' +
            'Company to act on or enforce any provision of this Agreement shall not be construed as\n' +
            'a waiver of that provision or any other provision in this Agreement. No waiver shall be\n' +
            'effective against the Company unless made in writing and signed by an authorized\n' +
            'representative of the Company. No such waiver shall be construed as a waiver in any\n' +
            'other or subsequent instance.</p>' +
            '<p>This Agreement is made and entered into in the Province of Ontario and is to be\n' +
            'construed under the laws of Canada as they may from time to time exist.</p>'
        }
      ]
    }
  }
}

</script>

<style>
.terms-and-conditions {
  font-size: 8px!important;
  line-height: 1.4;
}

.terms-and-conditions h2 {
  font-size: 10px!important;
}

.terms-and-conditions p {
  margin-bottom: 8px;
}

</style>
